<script setup>
import { ref, watch, defineProps } from 'vue';

const props = defineProps({
  step: Number,
  title: String,
  subtitle: String,
  canBeClosed: Boolean,
  iconPath: String,
  open: Boolean,
  bgColorSubtitle: {
    type: String,
    default: "wbco-bg-white"
  }
});

const isOpen = ref(props.open);

watch(
  () => props.open,
  (currentValue) => {
    isOpen.value = currentValue;
  }
);

function toggleOpen() {
  isOpen.value = !isOpen.value;
}

function showSubtitle(subtitle) {
  if (subtitle === undefined || subtitle === '') {
    return false
  } else {
    return true
  }
}

</script>
<template>
  <div class="wbco-bg-gray-100 wbco-rounded-lg ">
    <div data-name="header"
      class="wbco-grid wbco-rounded-t-lg wbco-bg-wbyellow wbco-border-2 wbco-gap-4  wbco-max-w-[100%] md:wbco-grid-cols-2 wbco-grid-cols-1"
      :class="{ 'wbco-rounded-b-lg': !isOpen }" @click="toggleOpen">
      <div class="wbco-flex wbco-items-center wbco-justify-between">
        <span
          class="wbco-bg-white wbco-rounded-full wbco-w-[48px] wbco-h-[48px] wbco-m-3 wbco-p-[12px] wbco-self-align-middle">
          <img :src=iconPath class="wbco-min-w-[24px] wbco-block wbco-h-auto wbco-align-middle wbco-max-w-[100%]">
        </span>
        <div class=" wbco-ml-3 wbco-text-start wbco-flex-grow">
          {{ title }}
        </div>
      </div>
      <div class="wbco-flex wbco-items-center ">
        <div  class="wbco-flex-grow"></div>
        <div v-show="showSubtitle(subtitle)" class="wbco-rounded-3xl wbco-p-2 wbco-font-light " :class="bgColorSubtitle">
          {{ subtitle }}
        </div>
        <div class="wbco-flex wbco-min-w-[48px] wbco-cursor-pointer wbco-justify-self-end wbco-px-2 ">
          <span class="wbco-p-2 wbco-mx-2 wbco-bg-white wbco-rounded-full hover:wbco-bg-gray-100 " >
            <!--chevron up -->
            <svg v-if="isOpen" xmlns="http://www.w3.org/2000/svg"
              class="wbco-w-[28px] wbco-h-[28px] wbco-stroke-wbbrown wbco-align-middle" fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
            </svg>
            <!-- chevron down -->
            <svg v-if="!isOpen" xmlns="http://www.w3.org/2000/svg" class="wbco-w-[28px] wbco-h-[28px] wbco-stroke-wbbrown"
              fill="none" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </span>
        </div>
      </div>

    </div>
    <div data-name="body"
      class="wbco-relative wbco-overflow-hidden wbco-duration-500 wbco-ease-in-out wbco-transition-height"
      :class="{ 'wbco-max-h-0 wbco-hidden': !isOpen }">
      <div class="wbco-container wbco-px-4 wbco-py-4 wbco-mx-auto" v-show="isOpen">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
