<template>
  <div>
    <p class="wbco-border-x-wbbrown wbco-mt-4 wbco-mb-2">Bitte wählen Sie das gewünschte WDV-System aus.</p>
    <div class="wbco-flex wbco-flex-col md:wbco-flex-row">
      <div class="md:wbco-flex-1">
        <div class="wbco-m-4">
          <label for="wdvs-select" class="wbco-font-bold wbco-mt-4 wbco-mb-2  wbco-w-full">Wärmedämmverbundsystem:
            <select v-model="wdvsSelected" id="wdvs-select"
              class="wbco-w-100"
              @change="selectFavs(wdvsSelected)">
              <option v-for="option in wdvsOptions" :value="option" :key="option.ID">{{ option.name }}</option>
            </select>
          </label>
        </div>
        <div v-if="exists(wdvsSelected.kleber)" class="wbco-m-4">
          <label for="kleber-select" class="wbco-font-bold  wbco-mt-4 wbco-mb-2  wbco-w-full">{{ kleberTitle }}:
            <select v-model="kleberSelected" id="kleber-select"
              class="wbco-w-100"
              @change="calculateProductionCO2Cost(kleberSelected, daemmungSelected, putzSelected, armierungSelected, thicknessSelected, areaSelected )">
              <option v-for="kleber in wdvsSelected.kleber" :value="kleber" :key="kleber.ID">{{ kleber.name }}</option>
            </select>
          </label>
        </div>
        <div class="wbco-m-4">
          <label for="daemmplatte-select" class="wbco-font-bold wbco-mt-4 wbco-mb-2  wbco-w-full">Wärmedämmungsplatte:
            <select v-model="daemmungSelected" id="daemmplatte-select"
              class="wbco-w-100"
              @change="calculateUValue(daemmungSelected, putzSelected, thicknessSelected, uValueOld)">
              <option v-for="daemmung in wdvsSelected.daemmplatten" :value="daemmung" :key="daemmung.ID">{{ daemmung.name}}
              </option>
            </select>
          </label>
        </div>
      </div>
      <div class="md:wbco-flex-1">
        <div class="wbco-m-4">
          <label for="putz-select" class="wbco-font-bold wbco-mt-4 wbco-mb-2  wbco-w-full">{{ putzTitle }}:
            <select v-model="putzSelected" id="putz-select"
              class="wbco-w-100"
              @change="calculateUValue(daemmungSelected, putzSelected, thicknessSelected, uValueOld)">
              <option v-for="putz in wdvsSelected.putze" :value="putz" :key="putz.ID">
                {{ putz.name }}
              </option>
            </select>
          </label>
        </div>
        <div class="wbco-m-4">
          <label for="armierung-select" class="wbco-font-bold wbco-mt-4 wbco-mb-2 wbco-w-full">Armierungsmörtel:
            <select v-model="armierungSelected" id="armierung-select"
              class="wbco-w-full"
              @change="calculateProductionCO2Cost(kleberSelected, daemmungSelected, putzSelected, armierungSelected, thicknessSelected, areaSelected)">
              <option v-for="armierung in wdvsSelected.armierungen" :value="armierung" :key="armierung.ID">
                {{ armierung.name }}
              </option>
            </select>
          </label>
        </div>
      </div>
    </div>

    <div class="wbco-flex wbco-flex-col wbco-m-4">
      <label for="dicke-select" class="wbco-font-bold wbco-mt-4 wbco-mb-2">Dämmstoffdicke:</label>
      <div class="wbco-flex wbco-flex-row " id="dicke-select">
        <div class="wbco-bg-white wbco-rounded-lg wbco-flex wbco-flex-1 wbco-px-5 wbco-border wbco-border-wbbrown">
          <input type="range" v-model="thicknessSelected" min="20" max="400" step="20" class="wbco-flex wbco-flex-1"
          @change="calculateUValue(daemmungSelected, putzSelected, thicknessSelected, uValueOld)">
        </div>
        <div>
          <input type="number" v-model="thicknessSelected" min="20" max="400" step="20"
            class="wbco-border wbco-rounded-lg wbco-font-light wbco-text-center focus:wbco-outline-none wbco-w-48 wbco-ml-10"
            @change="calculateUValue(daemmungSelected, putzSelected, thicknessSelected, uValueOld)">
        </div>
        <div class="wbco-px-5 wbco-w-10 wbco-text">
          <p>mm</p>
        </div>
      </div>
    </div>
    <div class="wbco-flex wbco-flex-col wbco-m-4">
      <label for="dicke-select" class="wbco-font-bold wbco-mt-4 wbco-mb-2">Fassadenfläche:</label>
      <div class="wbco-flex wbco-flex-row " id="dicke-select">
        <div class="wbco-bg-white wbco-rounded-lg wbco-flex wbco-flex-1 wbco-px-5 wbco-border wbco-border-wbbrown">
          <input type="range" v-model="areaSelected" min="10" max="1000" step="1" class="wbco-flex wbco-flex-1"
            @change="areaChanged($event)">
        </div>
        <div>
          <input type="number" v-model="areaSelected" min="10" max="1000" step="1"
            class="wbco-border wbco-rounded-lg wbco-font-light wbco-text-center focus:wbco-outline-none wbco-w-48 wbco-ml-10"
            @input="areaChanged($event)">
        </div>
        <div class="wbco-px-5 wbco-w-10 wbco-text">
          <p>m<sup>2</sup></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import json from '../data/wdvs-systems.json'
export default {
  data() {
    return {
      thicknessSelected: 100,
      areaSelected: 100,
      wdvsSelected: {},
      kleberSelected: {},
      daemmungSelected: {},
      putzSelected: {},
      armierungSelected: {},
      wdvsOptions: json.configurations,
      newLambda:0,
      kleberTitle:"Klebemörtel",
      putzTitle:"Oberputz"
    };
  },
  props: ['uValueNew', 'area', 'uValueOld', 'productionCO2Cost'],
  emits: ['update:uValueNew', 'update:area', 'update:productionCO2Cost'],
  methods: {
    exists(list) {
      var exists = false;
      if (list)
        if (list.length > 0)
          exists = true;
      return exists;
    },
    calculateUValue(daemmung, putz, dicke, uwertAlt) {
      let uNew = 0;
      let lambdaNew = 0;
      if (!(typeof daemmung.lambdavalue === "undefined")) {
        lambdaNew += daemmung.lambdavalue;
      }
      if (!(typeof putz === "undefined")) {
          lambdaNew +=  putz.lambdavalue;
      }
      if ((lambdaNew > 0) && (uwertAlt > 0) ) {
        uNew = Math.round((1 / ((1 / uwertAlt) + ((dicke/1000) / lambdaNew))) * 1000) / 1000 // Dicke in Meter umrechnen, gerundet auf 3 Nachkomma-Stellen
        this.$emit('update:uValueNew', uNew);
        this.calculateProductionCO2Cost(this.kleberSelected, this.daemmungSelected, this.putzSelected, this.armierungSelected, this.thicknessSelected, this.areaSelected)
      }
    },
    calculateProductionCO2Cost(kleber, daemmung, putz, armierung, dicke, flaeche ) {
      let co2Costs = 0
      if (Object.keys(kleber).length>0) {
        if (kleber.emissions) {
          co2Costs += kleber.emissions
        }
      }
      
      if (Object.keys(daemmung).length>0) {
        if (daemmung.emissions) {
          co2Costs += (daemmung.emissions * (dicke / 1000))
        }
      }
      
      if (Object.keys(armierung).length>0) {
        if (armierung.emissions) {
          co2Costs += armierung.emissions
        }
      }

      if (Object.keys(putz).length>0) {
        if (putz.emissions) {
          co2Costs += putz.emissions
        }
      } 
      co2Costs = flaeche * co2Costs
      this.$emit("update:productionCO2Cost", co2Costs)
      return co2Costs
    },
    areaChanged(event) {
      this.$emit('update:area', event.target.value)
      this.calculateProductionCO2Cost(this.kleberSelected, this.daemmungSelected, this.putzSelected, this.armierungSelected, this.thicknessSelected, this.areaSelected )
    },
    selectFavs(WDVS) {
      WDVS.kleber.forEach(kleber => {
        if (kleber.fav) {
          this.kleberSelected = kleber;
        }
      });
      WDVS.daemmplatten.forEach(daemmung => {
        if (daemmung.fav) {
          this.daemmungSelected = daemmung;
        }
      });
      WDVS.putze.forEach(putz => {
        if (putz.fav) {
          this.putzSelected = putz;
        }
      });
      WDVS.armierungen.forEach(armierung => {
        if (armierung.fav) {
          this.armierungSelected = armierung;
        }
      });
      if (this.wdvsSelected.ID === "weber.therm circle WDVS") {
        this.kleberTitle = "Armierungsgrundputz";
        this.putzTitle = "Oberputz";
      }
      else {
        this.kleberTitle = "Klebemörtel";
        this.putzTitle = "Oberputz";
      }
      this.calculateUValue(this.daemmungSelected, this.putzSelected, this.thicknessSelected, this.uValueOld);
    }
  }
};
</script>
