<template>
  <div class="md:wbco-space-y-4">
    <div class="wbco-p-4">
      <p>Bitte geben Sie den U-Wert Ihres Hauses an. Sollten Sie diesen nicht wissen, können Sie auch das Baujahr
        auswählen, dann wird automatisch eine Schätzung eingetragen.</p>
    </div>
    <div class="wbco-flex wbco-justify-center">
      <div class="md:wbco-flex-row wbco-justify-center md:wbco-w-2/3">
        <div class="wbco-flex">
          <span class="tab md:wbco-w-1/2" :class="{ active: activeTab === 'tab2' }" @click="activeTab = 'tab2'">U-Wert der
            Außenwand</span>
          <span class="tab md:wbco-w-1/2" :class="{ active: activeTab === 'tab1' }" @click="activeTab = 'tab1'">Baujahr des
            Hauses</span>
        </div>
        <div v-show="activeTab === 'tab1'" class="tab-content wbco-bg-gray-200 wbco-p-4">
          <div id="building_age" class="wbco-pb-4">
            <select :value=modelValue
              class="wbco-relative wbco-w-full wbco-py-2 wbco-pl-3 wbco-mt-4 wbco-text-left wbco-border wbco-rounded-lg wbco-cursor-default wbco-bg-white wbco-border-wbbrown focus:wbco-outline-none focus-visible:wbco-border-indigo-500 focus-visible:wbco-ring-2 focus-visible:wbco-ring-white focus-visible:wbco-ring-opacity-75 focus-visible:wbco-ring-offset-2 focus-visible:wbco-ring-offset-orange-300"
              @input="$emit('update:modelValue', $event.target.value)">
              <option v-for="option in baujahr" :value="option.value" :key="option.id">{{ option.label }}</option>
            </select>
          </div>
        </div>
        <div v-show="activeTab === 'tab2'" class="tab-content wbco-bg-gray-200 wbco-p-4">
          <div id="u_value_input_before" class="">
            <div class="wbco-bg-gray-200 wbco-p-4 wbco-flex">
              <div class="wbco-bg-white wbco-rounded-lg wbco-flex wbco-flex-1 wbco-px-5 wbco-border wbco-border-wbbrown">
                <input type="range" :value=modelValue min=0.05 max=2 step=0.05 class="wbco-flex wbco-flex-1"
                  @input="$emit('update:modelValue', $event.target.value)" />
              </div>
              <input type="number" :value=modelValue step=0.05 id="uValue"
                class="wbco-border wbco-rounded-lg wbco-w-20 wbco-font-light wbco-text-center focus:wbco-outline-none wbco-ml-4 "
                @input="$emit('update:modelValue', $event.target.value)" />
              <span class="wbco-pt-2 wbco-pl-2">W/(m²K)</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  data() {
    return {
      uValueInput: 1,
      baujahr: [
        { label: '     < 1948', value: 1.7, id: 'opt1' },
        { label: '1949 - 1968', value: 1.4, id: 'opt2' },
        { label: '1969 - 1978', value: 1.0, id: 'opt3' },
        { label: '1979 - 1983', value: 0.8, id: 'opt4' },
        { label: '1984 - 1994', value: 0.6, id: 'opt5' },
        { label: '     > 1995', value: 0.5, id: 'opt6' },
      ],
      activeTab: 'tab1'
    };
  },
  props: ['modelValue'],
  emits: ['update:modelValue'],
};
</script>

<style>
#uValue {
  width: 50px;
  text-align: right;
}

.co2-input-range {
  width: 300px;
}

.co2-input-form {
  margin-top: 50px;
}



/* Stil für die Reiter */

.tab:hover {
  border: 5px #F5F5F5;
  background-color: #CCCCCC;
}

.tab {
  padding: 8px 16px;
  cursor: pointer;
  border: 1px solid #CCCCCC;
  border-bottom: none;
  background-color: #F5F5F5;
}

.tab.active {
  background-color: #FFEB00;
}

/* Stil für den Reiter-Inhalt */
.tab-content {
  border: 1px solid #CCCCCC;
  padding: 16px;
  margin-top: -1px;
}

.tab-content.show {
  display: block;
}</style>
