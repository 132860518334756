<template>
      <p> Die folgenden Angaben beziehen sich auf eine geschätzte, durchschnittliche Lebensdauer eines WDVS von 40 Jahren. </p>
  <div class="wbco-grid wbco-grid-cols-1 md:wbco-grid-cols-2">
    <div class="wbco-flex wbco-m-3 wbco-items-center">
      <div class="wbco-w-[36px] wbco-flex-none">
        <img :src="imgSavings" class="wbco-w-[32px]" />
      </div>
      <label for="co2Savings" class="wbco-pl-5 wbco-w-[200px] md:wbco-w-[300px] wbco-text-left">
        Ihre CO<sub>2</sub> Einsparungen:
      </label>
    </div>
    <div class="wbco-flex wbco-m-3">
      <input type="number" id="co2Savings" readonly="true" :value="calculateCO2(calValues)"
        class="wbco-border wbco-rounded-lg wbco-w-28 wbco-h-12 wbco-font-light wbco-text-center focus:wbco-outline-none wbco-self-center" />
      <span class="wbco-pl-3 wbco-w-30 wbco-text-left wbco-flex-1 wbco-self-center">t CO<sub>2</sub>-Äquivalente. </span>
      <div class="wbco-flex-1"></div>
    </div>
    <div class="wbco-flex wbco-m-3 wbco-items-center">
      <div class="wbco-w-[36px] wbco-flex-none">
        <img :src="imgTreesavings" class="wbco-w-[32px]" />
      </div>
      <div>
        <label for="treesSavings" class="wbco-pl-5 wbco-w-[200px] md:wbco-w-[300px] wbco-text-left">
          Das entspricht etwa der CO<sub>2</sub>-Absorption von:
        </label>
      </div>
    </div>
    <div class="wbco-flex wbco-m-3 wbco-items-center">
      <input type="number" id="treesSavings" readonly="true" :value="calculateTrees(co2Einsparung)"
        class="wbco-border wbco-rounded-lg wbco-w-28 wbco-h-12 wbco-font-light wbco-text-center focus:wbco-outline-none wbco-self-center" />
      <span class="wbco-pl-3 wbco-w-30  wbco-text-left wbco-self-center">Buchen in 40 Jahren.</span>
      <div class="wbco-flex-1"></div>
    </div>
    <div class="wbco-flex wbco-m-3 wbco-justify-start wbco-items-center">
      <div class="wbco-w-[36px] wbco-flex-none">
        <img :src="imgAmortisation" class="wbco-w-[32px]" />
      </div>
      <div>
        <label for="co2Amortisation" class="wbco-pl-5 wbco-w-[200px] md:wbco-w-[300px] wbco-text-left">
          Die CO<sub>2</sub>-Amortisationszeit Ihres WDVS beträgt ca.:
        </label>
      </div>
    </div>
    <div class="wbco-flex wbco-m-3 wbco-justify-start">
      <input type="number" id="co2Amortisation" readonly="true"
        :value="calculateAmortisation(co2Einsparung, calValues.co2eqEmissionProductionInsulation)"
        class="wbco-border wbco-rounded-lg wbco-w-28 wbco-h-12 wbco-font-light wbco-text-center focus:wbco-outline-none wbco-self-center" />
      <span class="wbco-pl-3 wbco-w-30  wbco-text-left wbco-self-center">Jahre.</span>
      <div class="wbco-flex-1"></div>
    </div>
    <p class="wbco-font-wbmedium wbco-pt-5 md:wbco-col-span-2">Sollten Sie sich für unser Wärmedämmverbundsystem entscheiden, haben Sie in 40 Jahren CO<sub>2</sub>-Äquivalente Einsparungen in Höhe von <b>{{ Number(co2Einsparung).toLocaleString() }} t</b>. Dies entspricht der CO<sub>2</sub>-Speicherung von <b>{{ anzahlBaeume }}</b> Buchen.</p>
    <br>
    <p v-if="amortisationszeit" class="wbco-font-wbmedium wbco-pt-5 md:wbco-col-span-2">Bereits nach ca. <b>{{ Number(amortisationszeit).toLocaleString() }} Jahren</b> ist
      die CO<sub>2</sub>-Amortisationszeit des Wärmedämmverbundsystems
      erreicht.</p> 
  </div>
</template>

<script>
export default {
  data() {
    return {
      amortisationszeit: 0,
      co2Einsparung: 0,
      anzahlBaeume: 0,
      imgSavings: process.env.BASE_URL + '/img/erde.svg',
      imgTreesavings: process.env.BASE_URL + '/img/blatt.svg',
      imgAmortisation: process.env.BASE_URL + '/img/amortisationszeit.svg'
    };
  },
  props: ['calValues'],
  methods: {
    calculateCO2(calValues) {
      let co2Savings = 0
      let energySavings = (calValues.insulationArea * (calValues.uValueCurrent - calValues.uValueInsulated) * calValues.heatingDays * calValues.productionEffortNumberHeating) / 1000
      energySavings = energySavings.toFixed(2)
      co2Savings = energySavings * (calValues.co2EmissionFactorHeating / 1000) * 40
      co2Savings = co2Savings.toFixed(2)
      this.co2Einsparung = co2Savings
      return co2Savings
    },
    calculateTrees(co2Savings) {
      let trees = (co2Savings * 1000 / 12.5).toFixed(0)
      this.anzahlBaeume = trees
      return trees
    },
    calculateAmortisation(co2Savings40y, co2eqEmissionProduction) {
      console.log("Produktion: " + co2eqEmissionProduction)
      console.log("Savings: " + co2Savings40y)
      let amt = Math.round((co2eqEmissionProduction / 1000) / (co2Savings40y / 40)  * 100) / 100
      this.amortisationszeit = amt
      return amt
    },
    calculateTreeArea(trees) {
      return Math.round(trees / 500)
    }
  },
};
</script>

<style>
.not-extendable {
  width: 100%;
  padding-inline: 10%;
}
</style>