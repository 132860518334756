<template>
  <div>
    <h3 class="wbco-font-wbregular wbco-text-2xl wbco-m-4">Bitte geben Sie die Gebäudeart an.</h3>
    <div class="wbco-flex wbco-flex-col md:wbco-flex-row wbco-bg-gray-200 wbco-p-4 md:wbco-space-x-4 md:wbco-py-4">
      <label key="MFHouse"
        class="md:wbco-flex-1 wbco-p-4 wbco-text-center wbco-border wbco-rounded-lg wbco-cursor-pointer wbco-border-wbbrown hover:wbco-bg-gray-200 hover:wbco-text-black"
        :class="{
          'wbco-text-black wbco-bg-wbyellow hover:wbco-bg-wbyellow': hausTypSelected === 'MFH',
          'wbco-bg-white wbco-text-wbbrown': hausTypSelected !== 'MFH'
        }">
        <input type="radio" id="MFHouse" class="wbco-hidden" v-model="hausTypSelected" value="MFH"
          @input="setProductionEffort(baualterSelected, $event.target.value)">
        Mehrfamilienhaus
      </label>
      <label key="EFHouse"
        class="md:wbco-flex-1 wbco-p-4 wbco-text-center wbco-border wbco-rounded-lg wbco-cursor-pointer wbco-border-wbbrown hover:wbco-bg-gray-200 hover:wbco-text-black"
        :class="{
          'wbco-text-black wbco-bg-wbyellow hover:wbco-bg-wbyellow': hausTypSelected === 'EFH',
          'wbco-bg-white wbco-text-wbbrown': hausTypSelected !== 'EFH'
        }">
        <input type="radio" id="EFHouse" class="wbco-hidden" v-model="hausTypSelected" value="EFH"
          @input="setProductionEffort(baualterSelected, $event.target.value)">
        Einfamilienhaus
      </label>
    </div>
    <h3 class="wbco-font-wbregular wbco-text-2xl wbco-m-4">Bitte geben Sie die Art Ihrer Heizung an.</h3>
    <div class="wbco-p-4 wbco-space-y-4">
      <div class="float">
        <label for="energietraeger-selector" class="wbco-left">Energieträger:</label>
        <select v-model="energietraegerSelected" id="energietraeger-selector"
          class="wbco-relative wbco-w-full wbco-py-2 wbco-pl-3 wbco-text-left wbco-border wbco-rounded-lg wbco-cursor-default wbco-bg-white wbco-border-wbbrown focus:wbco-outline-none focus-visible:wbco-border-indigo-500 focus-visible:wbco-ring-2 focus-visible:wbco-ring-white focus-visible:wbco-ring-opacity-75 focus-visible:wbco-ring-offset-2 focus-visible:wbco-ring-offset-orange-300"
          @change="$emit('update:CO2Emissionsfaktor', energietraegerSelected.CO2Emissionsfaktor)">
          <option v-for="energietraeger in energietraegerListe" :value="energietraeger" :key="energietraeger.ID">{{
            energietraeger.energieträger }}</option>
        </select>
      </div>
      <div v-show="energietraegerSelected">
        <label for="heizungsart-selector">Heizungsart:</label>
        <select v-model="heizungsartSelected" id="heizungsart-selector"
          class="wbco-relative wbco-w-full wbco-py-2 wbco-pl-3 wbco-text-left wbco-border wbco-rounded-lg wbco-cursor-default wbco-bg-white wbco-border-wbbrown focus:wbco-outline-none focus-visible:wbco-border-indigo-500 focus-visible:wbco-ring-2 focus-visible:wbco-ring-white focus-visible:wbco-ring-opacity-75 focus-visible:wbco-ring-offset-2 focus-visible:wbco-ring-offset-orange-300">
          <option v-for="heizungsart in energietraegerSelected.heizungsarten" :value="heizungsart"
            :key="heizungsart.name">
            {{ heizungsart.name }}</option>
        </select>
      </div>
      <div :v-if="heizungsartSelected">
        <label for="baualter-selector">Baujahr:</label>
        <select v-model="baualterSelected" id="baualter-selector"
          class="wbco-relative wbco-w-full wbco-py-2 wbco-pl-3 wbco-text-left wbco-border wbco-rounded-lg wbco-cursor-default wbco-bg-white wbco-border-wbbrown focus:wbco-outline-none focus-visible:wbco-border-indigo-500 focus-visible:wbco-ring-2 focus-visible:wbco-ring-white focus-visible:wbco-ring-opacity-75 focus-visible:wbco-ring-offset-2 focus-visible:wbco-ring-offset-orange-300"
          @change="setProductionEffort(baualterSelected, hausTypSelected)">
          <option v-for="baualterklasse in heizungsartSelected.baualterklassen" :value="baualterklasse"
            :key="baualterklasse.ID">
            {{ baualterklasse.name }}
          </option>
        </select>
      </div>

    </div>
  </div>
</template>
<script>
import heizungsartenJSON from '../data/heizungsarten.json'

export default {
  data() {
    return {
      energietraegerListe: heizungsartenJSON.Energieträger,
      energietraegerSelected: {},
      heizungsartSelected: {},
      baualterSelected: {},
      hausTypSelected: 'MFH'
    }
  },
  props: ['erzeugeraufwandszahl', 'CO2Emissionsfaktor'],
  emits: ['update:erzeugeraufwandszahl', 'update:CO2Emissionsfaktor'],
  methods: {
    setProductionEffort(baualter, haustyp) {
      var eaz = 0
      if (Object.keys(baualter).length > 0) {
        if (haustyp === "MFH") {
          eaz = baualter.erzeugeraufwandszahlMFH
        }
        else {
          eaz = baualter.erzeugeraufwandszahlEFH
        }
      }
      this.$emit("update:erzeugeraufwandszahl", eaz)
      return eaz
    }
  },
  watch: {
    energietraegerSelected(newVal) {
      if (newVal.heizungsarten.length == 1) {
        this.heizungsartSelected = newVal.heizungsarten[0]
      }
      else {
        this.heizungsartSelected = {}
      }
    },
    heizungsartSelected(newVal) {
      if (Object.keys(newVal).length > 0) {
        if (newVal.baualterklassen.length == 1) {
          this.baualterSelected = newVal.baualterklassen[0]
        }
        else {
          this.baualterSelected = {}
        }
      }
      else {
        this.baualterSelected = {}
      }
      this.setProductionEffort(this.baualterSelected, this.hausTypSelected)
    }
  }
};
</script>