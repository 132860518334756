<template>
    <div id="calculator" class="calculator-main-div wbco-max-w-screen-lg wbco-mx-auto">
        <div id="calculator" v-if="showCalculator === true">
            <Calculator />
        </div>
        <div class="wbco-flex wbco-flex-col wbco-rounded-lg md:wbco-flex-row wbco-bg-gray-200 wbco-p-4 md:wbco-space-x-4 md:wbco-py-4 wbco-mt-8">
            <button
                v-if="showCalculator === false"
                class="md:wbco-flex-1 wbco-p-4 wbco-text-center wbco-border wbco-rounded-lg wbco-cursor-pointer wbco-border-wbbrown hover:wbco-bg-gray-200 wbco-text-black wbco-bg-wbyellow hover:wbco-bg-wbyellow'"
                @click="startApp()">
                Starte die App
            </button>
            <a :href="getEnvPath('/doc/Dokumentation Weber Klimarechner.pdf')"
                class="md:wbco-flex-1 wbco-p-4 wbco-text-center wbco-border wbco-rounded-lg wbco-cursor-pointer wbco-border-wbbrown hover:wbco-bg-gray-200 wbco-text-black wbco-bg-wbyellow hover:wbco-bg-wbyellow'"
                target="_blank">
                Hier gibt es mehr Hintergrundwissen!
            </a>
        </div>
        <span class="footer-copyright float-right">© 2023 Saint-Gobain Weber. All rights reserved.</span>
    </div>
</template>
<script>
import Calculator from './components/Calculator.vue';
export default {
    name: 'App',
    components: {
        Calculator
    },
    data() {
        return {
            showCalculator: false
        };
    },
    methods: {
        startApp() {
            this.showCalculator = true;
        },
        getEnvPath(relPath) {
            return process.env.BASE_URL + relPath
        }
    }
}
</script>
<style>
#calculator input, #calculator select {
    font-size: 16px !important;
}
</style>