<template>
        <Collapsible step.number='1' title="Wärmedurchlässigkeit der Außenwand" :subtitle="getHouseSubtitle(calcValues.uValueCurrent)" :canBeClosed="false" :open="checkCurrentStep(1, currentStep)" :iconPath="imgHaus" :bgColorSubtitle="getUValueBGColor(calcValues.uValueCurrent)"
        @click="toggleStep(1)" >
            <Haus v-model=calcValues.uValueCurrent />
        </Collapsible>
        <Collapsible step.number='2' title="Heizungsart" :subtitle="getHeatingSubtitle(calcValues.productionEffortNumberHeating, calcValues.co2EmissionFactorHeating)" :canBeClosed="false" :open="checkCurrentStep(2, currentStep)" :iconPath="imgHeizung"
        @click="toggleStep(2)">
            <Heizung v-model:erzeugeraufwandszahl=calcValues.productionEffortNumberHeating  v-model:CO2Emissionsfaktor="calcValues.co2EmissionFactorHeating"/>
        </Collapsible>
        <Collapsible step.number='3' title="Klimazone" :subtitle="getClimateSubtitle(calcValues.heatingDays)" :canBeClosed="false" :open="checkCurrentStep(3, currentStep)" :iconPath="imgKlima"
        @click="toggleStep(3)">
            <Klima v-model:heizgradtage="calcValues.heatingDays" />
        </Collapsible>
        <Collapsible step.number='4' title="Auswahl der Wärmedämmung" :subtitle="getInsulationSubtitle(calcValues.uValueInsulated, calcValues.co2eqEmissionProductionInsulation)" :canBeClosed="false" :open="checkCurrentStep(4, currentStep)" :iconPath="imgDaemmung" :bgColorSubtitle="getUValueBGColor(calcValues.uValueInsulated)"
        @click="toggleStep(4)">
            <Daemmung 
            v-model:uValueNew="calcValues.uValueInsulated" 
            v-model:area="calcValues.insulationArea"
            v-model:productionCO2Cost="calcValues.co2eqEmissionProductionInsulation"
            v-model:uValueOld="calcValues.uValueCurrent"
            />
        </Collapsible>
        <Collapsible step.number='5' title="Ergebnis" :canBeClosed="false" :open="true" :iconPath="imgErgebnis" subtitle="">
            <Ergebnis :calValues="calcValues" />
        </Collapsible>
</template>
<script>

import Daemmung from './Dämmung.vue';
import Haus from './Haus.vue';
import Heizung from './Heizung.vue';
import Klima from './Klima.vue';
import Ergebnis from './Ergebnis.vue';
import Collapsible from './controls/Collapsible.vue';

export default {
    components: {
        Collapsible,
        Daemmung,
        Haus,
        Heizung,
        Klima,
        Ergebnis
    },
    data() {
        return {
            currentStep: 0,
            calcValues: {
                uValueCurrent: 0,
                uValueInsulated: 0,
                insulationArea:100,
                productionEffortNumberHeating: 0,
                co2EmissionFactorHeating: 0,
                heatingDays: 0,
                co2eqEmissionProductionInsulation: 0
            },
            imgHaus: process.env.BASE_URL + '/img/haus.svg',
            imgHeizung: process.env.BASE_URL + '/img/heizung.svg',
            imgKlima: process.env.BASE_URL + '/img/klima.svg',
            imgDaemmung: process.env.BASE_URL + '/img/daemmung.svg',
            imgErgebnis: process.env.BASE_URL + '/img/ergebnis.svg'
        };
    },
    methods: {
        toggleStep(step) {
            this.currentStep = step;
            return step
        },
        checkCurrentStep(step, currentStep) {
            if (step === currentStep)
                return true
            else {
                return false
            }
        },
        getHeatingSubtitle(productionEffort, co2Factor) {
            let subtitle = ''
            if (productionEffort === undefined || co2Factor === undefined || productionEffort === 0 || co2Factor === 0) {
                subtitle = ''
            } else {
                subtitle = 'Erzeugeraufwandzahl: ' +  Number(productionEffort).toLocaleString()
            }
            return subtitle
        },
        getHouseSubtitle(uOld) {
            let subtitle = ''
            if (uOld === undefined || uOld === 0) {
                subtitle = ''
            } else {
                subtitle = 'U-Wert aktuell: ' +  Number(uOld).toLocaleString() + ' W/(m²K) '
            }
            return subtitle
        },
        getClimateSubtitle(days) {
            let subtitle = ''
            if (days === undefined || days === 0) {
                subtitle = ''
            } else {
                subtitle = 'Heizgradstunden: ' +  Number(days).toLocaleString() + ' kKh/a '
            }
            return subtitle
        },
        getInsulationSubtitle(uNew, CO2Prod) {
            let subtitle = ''
            console.log("unew: "+ uNew + " || co2: " + CO2Prod )
            if (uNew === undefined || uNew === 0 ) {
                subtitle = ''
            } else {
                subtitle = 'U-Wert neu: ' + Number(uNew).toLocaleString() + ' W/(m²K) ' // | CO2-Äquivalent in Produktion: ' + Number(CO2Prod).toLocaleString() + ' kg'
            }
            return subtitle
        },
        getUValueBGColor(uValue) {
            let bgColorClass = "wbco-bg-white"
            if (uValue > 1) {
                bgColorClass = "wbco-bg-ufreeze"
            }
            else if (0.5 < uValue && uValue <= 1) {
                bgColorClass = "wbco-bg-ucold"
            }
            else if (0.35 <  uValue && uValue <= 0.5) {
                bgColorClass = "wbco-bg-ucool"
            }
            else if (0.25 <  uValue && uValue <= 0.35) {
                bgColorClass = "wbco-bg-unewstandard"
            }
            else if (0.15 <  uValue && uValue <= 0.25) {
                bgColorClass = "wbco-bg-ulowenergy"
            }
            else {
                bgColorClass = "wbco-bg-upassive"
            }
            return bgColorClass
        },
        addCurrentDomain(relativePath) {
            let fullPath = window.location.origin + relativePath
            return fullPath
        }
    },
};

</script>
<style>

</style>